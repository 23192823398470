.vision {
    overflow: hidden;
    border-radius: 0 180px 0 0;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      border-radius: 200px 0 0 0;
    }
  }
  
  .vision__flame {
    z-index: 1;
    position: absolute;
    bottom: 100%;
    right: 0;
    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 110px, 495px);
    transform: translateX(-25px) translateY(50%);
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 110px, 395px);
      transform: translateX(-25px) translateY(120px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      overflow: hidden;
      @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 110px, 495px);
      transform: translateX(0px) translateY(240px);
    }
  
    svg {
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        transform: translateX(105px);
      }
    }
  }
  
  .vision__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .vision__future {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 625px);
      }
    }
  
    .paragraph {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 625px);
      }
    }
  }
  