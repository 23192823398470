@mixin margin($position: null) {
  @for $i from 0 through $length {
    @if $i % $interval == 0 {
      &--#{$i} {
        @if $position == null {
          margin: $i * 1px !important;
        } @else {
          margin-#{$position}: $i * 1px !important;
        }
      }
    }
  }
}

@mixin marginPositions {
  @each $key, $value in $coords {
    &#{$key} {
      @include margin(#{$value});
    }
  }
}

.m {
  @include margin;
  @include marginPositions;
}

@each $key, $value in $breakpoints {
  .#{$key}\: {
    @media screen and (min-width: #{$value}) {
      &m {
        @include margin;
        @include marginPositions;
      }
    }
  }
}
