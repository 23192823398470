@mixin padding($position: null) {
  @for $i from 0 through $length {
    @if $i % $interval == 0 {
      &--#{$i} {
        @if $position == null {
          padding: $i * 1px !important;
        } @else {
          padding-#{$position}: $i * 1px !important;
        }
      }
    }
  }
}

@mixin paddingPositions {
  @each $key, $value in $coords {
    &#{$key} {
      @include padding(#{$value});
    }
  }
}

.p {
  @include padding;
  @include paddingPositions;
}

@each $key, $value in $breakpoints {
  .#{$key}\: {
    @media screen and (min-width: #{$value}) {
      &p {
        @include padding;
        @include paddingPositions;
      }
    }
  }
}
