.logistic {
    overflow: hidden;
    border-radius: 100px 0 0 0;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      border-radius: 200px 0 0 0;
    }
  }
  
  .logistic__image {
    z-index: 1;
    position: absolute;
    bottom: 100%;
    right: 0;
    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 308px, 833px);
    transform: translateY(65px);
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 389px, 533px);
      transform: translateY(120px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 389px, 833px);
      transform: translateY(170px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      overflow: hidden;
      transform: translateY(200px);
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  .logistic__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .logistic__operation {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 600px);
      }
    }
  
    .paragraph {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 600px);
      }
    }
  }
  