.careers {
    position: relative;
  }
  
  .careers__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      flex-direction: row;
    }
  }
  
  .careers__title {
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 480px);
    }
  }
  
  .careers__contact {
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 320px, 800px);
    }
  
    .careers__form__success {
      color: var(--font-green);
      @include responsive-type(24px, 40px);
      font-weight: var(--bold);
      line-height: 1;
    }
  
    .careers__form {
  
      &__inputs {
        display: flex;
        flex-direction: column;
  
        @media screen and (min-width: map-get($breakpoints, "md")) {
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 64px;
        }
  
        input {
          max-width: 100%;
          color: var(--font-dark);
          @include responsive-type(20px, 32px);
          font-weight: var(--bold);
          line-height: 1.2;
          border: 0;
  
          @media screen and (min-width: map-get($breakpoints, "lg")) {
            max-width: 240px;
          }
  
          @media screen and (min-width: map-get($breakpoints, "xl")) {
            max-width: 330px;
          }
  
          &::placeholder {
            color: rgba(10, 9, 12, 0.4);
          }
        }
      }
  
      &__button {
        cursor: pointer;
        padding: 12px 16px 10px;
        color: white;
        font-weight: var(--bold);
        line-height: 0.8;
        background-color: var(--bg-green);
        border: 0;
        border-radius: 20px;
      }
    }
  }
  