$breakpoints: ("sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1440px, "hg": 1600px);
$coords: ('t': top, 'b': bottom, 'l': left, 'r': right);
$interval: 8;
$length: 250;

$responsive-type-min-width: 320px !default;
$responsive-type-max-width: 1920px !default;

:root {
  --font: "Object Sans", serif;

  --thin: 250;
  --regular: 400;
  --bold: 700;
  --black: 900;

  --bg-dark: #0A090C;
  --bg-darkblue: #17263D;
  --bg-lightblue: #6999FF;
  --bg-green: #00C476;
  --bg-midgray: #E8E4E6;
  --bg-lightgray: #F0EDEE;
  --bg-white: white;

  --font-dark: #0A090C;
  --font-light: #F0EDEE;
  --font-darkblue: #2E53A1;
  --font-lightblue: #6999FF;
  --font-green: #00C476;
}
