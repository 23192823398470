.title {
  @include responsive-type(48px, 80px);
  line-height: 1.2;

  @media screen and (min-width: map-get($breakpoints, "md")) {
    line-height: 1;
  }

  &--sm {
    @include responsive-type(32px, 64px);
    line-height: 1.2;

    @media screen and (min-width: map-get($breakpoints, "md")) {
      line-height: 1.2;
    }
  }

  &--lg {
    @include responsive-type(48px, 100px);
    line-height: 1.2;

    @media screen and (min-width: map-get($breakpoints, "md")) {
      line-height: 1;
    }
  }


  /* TITLE WEIGTHS */
  &--bold {
    font-weight: var(--bold);
  }

  &--thin {
    font-weight: var(--bold);
  }


  /* TITLE COLORS */
  &--dark {
    color: var(--font-dark);
  }

  &--green {
    color: var(--font-green);
  }

  &--lightblue {
    color: var(--font-lightblue);
  }

  strong {
    font-weight: var(--bold);
  }

  /*
   * HACK: Just Because :(
  */
  &--vision {
    @include responsive-type(32px, 48px);
    line-height: 1.2;

    @media screen and (min-width: map-get($breakpoints, "md")) {
      line-height: 1.2;
    }
  }
}
