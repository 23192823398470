.footer {
  overflow: hidden;
  background-color: var(--bg-darkblue);
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    flex-direction: row;
  }
}

.footer__social {
  display: flex;
  justify-content: center;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    justify-content: flex-start;
  }

  li {
    margin-left: 32px;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    display: block;
    width: 35px;
    height: 35px;

    @media screen and (min-width: map-get($breakpoints, "lg")) {
      width: 62px;
      height: 62px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.footer__legal {
  display: flex;
  justify-content: center;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    justify-content: flex-start;
  }

  li {
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    color: rgba(white, 0.5);
    font-size: 14px;

    @media screen and (min-width: map-get($breakpoints, "lg")) {
      font-size: 16px;
    }

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
