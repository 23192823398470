.container {
  position: relative;
  margin: 0 auto;
  padding: 0 25px;
  
  @media screen and (min-width: map-get($breakpoints, "md")) {
    max-width: calc((1530 + (25 * 2)) / 1920 * 100%);
  }

  @media screen and (min-width: map-get($breakpoints, "hg")) {
    max-width: 1580px;
  }
}
