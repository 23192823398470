.discover {
    overflow: hidden;
    position: relative;
    background-image: url("../../images/discover-background-m.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 180px 200px 0;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      border-radius: 300px 0;
      background-position: bottom left;
    }
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      background-position: center left;
    }
  
    @media screen and (min-width: map-get($breakpoints, "xl")) {
      background-image: url("../../images/discover-background.png");
      background-position: initial;
    }
  }
  
  .discover__flame {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 100px, 251px);
    transform: translateX(-25px) translateY(50%);
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      transform: translateX(-100px) translateY(50%);
    }
  }
  
  .discover__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .title {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 700px);
      }
    }
  
    .paragraph {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 600px);
      }
    }
  }
  
  .discover__impacts {
    display: flex;
    flex-wrap: wrap;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      justify-content: space-between;
    }
  
    @media screen and (min-width: map-get($breakpoints, "xl")) {
      flex-direction: row;
    }
  }
  
  .discover__positiveness {
    @media screen and (min-width: map-get($breakpoints, "md")) {
      max-width: 45%;
    }
    
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      max-width: 380px;
    }
  
    &--economy,
    &--society {
      .discover__positiveness__icon {
        width: 80px;
  
        @media screen and (min-width: map-get($breakpoints, "md")) {
          width: 104px;
        }
      }
    }
  
    &--environment {
      .discover__positiveness__icon {
        width: 64px;
  
        @media screen and (min-width: map-get($breakpoints, "md")) {
          width: 86px;
        }
      }
    }
  
    h3 {
      color: var(--font-lightblue);
      font-size: 32px;
      line-height: 1;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        font-size: 48px;
      }
    }
  
    &__asterisc {
      display: inline-block;
      color: var(--font-light);
      font-size: 12px;
      font-style: italic;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        font-size: 14px;
      }
    }
  }
  