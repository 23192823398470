.button {
  display: inline-block;
  padding: 16px 24px;
  color: var(--font-dark);
  font-weight: var(--bold);
  white-space: nowrap;
  background-color: var(--bg-green);
  box-shadow: 0 0 11px var(--bg-green);
  border-radius: 100px;
}
