.section {
    position: relative;
  
    &--discover {
      margin-top: -290px;
  
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        margin-top: -290px;
      }
    }
  
    &--vision {
      margin-top: -180px;
  
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        margin-top: -180px;
      }
    }
  
    &--logistic {
      margin-top: -180px;
  
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        margin-top: -180px;
      }
    }
  }
  