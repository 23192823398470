.header {
  z-index: 999;
  position: absolute;
  padding: 54px 0 12px 0;
  width: 100%;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    padding: 24px 0;
  }
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    display: none;

    @media screen and (min-width: map-get($breakpoints, "lg")) {
      display: block;
    }
  }
}
