.legal {
    position: relative;
  }
  
  .legal__content {
    overflow: hidden;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      margin: 0 auto;
      max-width: 1220px;
    }
  }