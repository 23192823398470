.solutions {
  position: relative;
  margin-top: 115px;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    margin-top: 130px;
  }

  .swiper-pagination {
    bottom: 0;
    margin-bottom: -48px;

    @media screen and (min-width: map-get($breakpoints, "lg")) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: var(--font-darkblue);
    border-radius: 20px;
    transition: width 0.3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    width: 40px;
  }
}

.solutions__menu {
  display: none;
  margin: 0 auto;
  margin-bottom: 40px;
  @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 1690px);

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__highlight {
    @include responsive-resize(margin-left, $responsive-type-min-width, $responsive-type-max-width, 10px, 32px);
    @include responsive-resize(margin-right, $responsive-type-min-width, $responsive-type-max-width, 10px, 32px);
    padding: 32px 20px;
    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 320px, 1100px);
    background-color: var(--bg-midgray);
    border-radius: 20px;

    h4 {
      @include responsive-type(14px, 24px);
      font-weight: var(--light);
      line-height: 1.2;
      text-align: center;
    }

    &__submenu {
      display: flex;
      justify-content: space-between;
    }
  }

  &__button {
    cursor: pointer;
    padding: 12px 16px;
    width: min-content;
    color: black;
    @include responsive-type(14px, 24px);
    font-weight: var(--bold);
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
    background: none;
    border: 0;
    border-radius: 20px;

    @media screen and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    &--active {
      color: white;
      text-decoration: none !important;
      background-color: var(--bg-lightblue);
      box-shadow: 0 0 11px var(--bg-lightblue) !important;

      .solutions__menu__button__icon {
        path {
          fill: var(--bg-lightgray);
        }
      }
    }

    &__icon {
      margin: 12px 0 16px;

      &--exploration {
        svg {
          @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 72px, 167px);
        }
      }

      &--liquefaction {
        svg {
          @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 76px, 170px);
        }
      }

      &--logistics {
        svg {
          @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 149px, 272px);
        }
      }

      &--storage {
        svg {
          @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 168px, 250px);
        }
      }

      &--client {
        svg {
          @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 78px, 172px);
        }
      }
    }
  }
}

.solutions__slide {
  padding-top: 90px;

  @media screen and (min-width: map-get($breakpoints, "md")) {
    padding-top: 120px;
  }

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    padding-top: 0;
    padding-right: 145px;
  }
}

.solutions__card {
  position: relative;
  margin: 0 auto;
  background: rgba($color: white, $alpha: 0.85);
  border-radius: 100px 0 50px;

  @include responsive-resize(padding-top, $responsive-type-min-width, $responsive-type-max-width, 125px, 115px);
  @include responsive-resize(padding-left, $responsive-type-min-width, $responsive-type-max-width, 40px, 155px);
  @include responsive-resize(padding-right, $responsive-type-min-width, $responsive-type-max-width, 40px, 285px);
  @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 300px, 1085px);
  @include responsive-resize(height, $responsive-type-min-width, $responsive-type-max-width, 380px, 400px);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: var(--bg-lightblue);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);

    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 175px, 300px);
    @include responsive-resize(height, $responsive-type-min-width, $responsive-type-max-width, 175px, 300px);

    @media screen and (min-width: map-get($breakpoints, "lg")) {
      top: 50%;
      left: 100%;
      transform: translateX(-50%) translateY(-50%);
    }

    &--exploration {
      svg {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 102px, 167px);
      }
    }

    &--liquefaction {
      svg {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 106px, 170px);
      }
    }

    &--logistics {
      svg {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 118px, 211px);
      }
    }

    &--storage {
      svg {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 112px, 164px);
      }
    }

    &--client {
      svg {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 108px, 172px);
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    color: var(--font-darkblue);
    @include responsive-type(24px, 56px);
    font-weight: var(--bold);
    line-height: 1;
  }

  &__text {
    @include responsive-type(14px, 18px);
    line-height: 1.4;

    b, strong {
      font-weight: var(--bold);
    }
  }
}
