.bullets {
  
  &--bold {
    li {
      font-weight: var(--bold);
    }
  }

  &--green {
    li {
      color: var(--font-light);

      &::before {
        background-color: var(--bg-green) !important;
      }
    }
  }

  &--lightblue {
    li {
      color: var(--font-light);

      &::before {
        background-color: var(--bg-lightblue) !important;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 32px;
    padding-left: 16px;
    @include responsive-type(16px, 24px);
    line-height: 1.4;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      border-radius: 8px;
      background-color: var(--bg-dark);
    }

    strong {
      font-weight: var(--bold);
    }
  }
}
