.background {
    &--darkblue {
      background-color: var(--bg-darkblue);
    }
  
    &--lightblue {
      background-color: var(--bg-lightblue);
    }
  
    &--midgray {
      background-color: var(--bg-midgray);
    }
  
    &--lightgray {
      background-color: var(--bg-lightgray);
    }
  
    &--white {
      background-color: var(--bg-white);
    }
  }
  