.about {
    position: relative;
  }
  
  .about__curve {
    overflow: hidden;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    transform: translateY(5px);
  
    &--d {
      display: none;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        display: block;
      }
    }
  
    &--m {
      display: block;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        display: none;
      }
    }
  }
  
  .about__frame {
    overflow: hidden;
    position: relative;
    border-radius: 0 0 0 100px;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      border-radius: 0 0 300px 0;
    }
  }
  
  .about__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .about__company {  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 260px, 620px);
    }
  }
  
  .about__numbers {
    @media screen and (min-width: map-get($breakpoints, "md")) {
      @include responsive-resize(margin-left, $responsive-type-min-width, $responsive-type-max-width, 0px, 70px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "xl")) {
      margin-left: initial;
      @include responsive-resize(margin-right, $responsive-type-min-width, $responsive-type-max-width, 0px, 70px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "hg")) {
      @include responsive-resize(margin-right, $responsive-type-min-width, $responsive-type-max-width, 0px, 140px);
    }
  }
  
  .about__highlight {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 126px;
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      flex-direction: row;
      margin-top: 80px;
    }
  
    &--deliver {
      .about__highlight__icon {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 224px, 376px);
        transform: translateX(-50%) translateY(-70%);
  
        @media screen and (min-width: map-get($breakpoints, "xxl")) {
          left: 0;
          transform: translateX(-290px) translateY(-50%);
        }
      }
    }
  
    &--people {
  
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        justify-content: flex-end;
      }
  
      .about__highlight__icon {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 218px, 306px);
        transform: translateX(-50%) translateY(-70%);
  
        @media screen and (min-width: map-get($breakpoints, "xxl")) {
          left: 100%;
          transform: translateX(-70px) translateY(-50%);
        }
      }
  
      h3 {
        @media screen and (min-width: map-get($breakpoints, "xxl")) {
          width: auto;
        }
      }
    }
  
    &--operation {
      .about__highlight__icon {
        @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 202px, 330px);
        transform: translateX(-50%) translateY(-70%);
  
        @media screen and (min-width: map-get($breakpoints, "xxl")) {
          left: 0;
          transform: translateX(-240px) translateY(-45%);
        }
      }
  
      h3 {
        display: flex;
      }
  
      .operations {
        display: flex;
        flex-direction: column;
  
        &--hours,
        &--days {
          display: flex;
          align-items: center;
          flex-direction: column;
  
          span {
            display: block;
            color: var(--font-light);
            @include responsive-type(14px, 16px);
            font-weight: var(--regular);
            line-height: 1.2;
            text-align: center;
  
            @media screen and (min-width: map-get($breakpoints, "lg")) {
              line-height: 1.4;
              text-align: left;
            }
          }
        }
      }
    }
  
    &__icon {
      position: absolute;
      top: 0;
      left: 50%;
  
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        top: 50%;
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
  
      h3 {
        color: white;
        @include responsive-type(48px, 64px);
        font-weight: var(--thin);
        text-align: center;
        line-height: 1;
        white-space: nowrap;
  
        @media screen and (min-width: map-get($breakpoints, "xxl")) {
          text-align: left;
        }
  
        strong {
          @include responsive-type(56px, 100px);
          font-weight: var(--bold);
        }
  
        sup {
          @include responsive-type(24px, 32px);
          font-weight: var(--regular);
        }
      }
  
      p {
        text-align: center;
  
        @media screen and (min-width: map-get($breakpoints, "xl")) {
          text-align: left;
        }
      }
    }
  }
  