.work {
    overflow: hidden;
    background-image: url("../images/work-background-m.png");
    background-position: -100px 250px;
    background-repeat: no-repeat;
    background-size: 150%;
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      background-image: url("../images/work-background.png");
      background-position: -100px 450px;
      background-size: contain;
    }
  }
  
  .work__content {
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: map-get($breakpoints, "xxl")) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .title {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 720px);
      }
    }
  
    .paragraph {
      @media screen and (min-width: map-get($breakpoints, "xxl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 600px);
      }
    }
  }
  