.paragraph {
  overflow-wrap: break-word;
  @include responsive-type(14px, 18px);
  line-height: 1.4;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    line-height: 1.6;
  }

  &--bold {
    font-weight: var(--bold);
  }

  &--dark {
    color: var(--font-dark);
  }

  &--light {
    color: var(--font-light);
  }

  &--lg {
    @include responsive-type(16px, 24px);
  }

  strong {
    font-weight: var(--bold);
  }
}
