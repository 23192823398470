.webdoor {
    overflow: hidden;
    position: relative;
  
    &--about {
      margin: 0 auto;
      background-image: url("../../images/about-background-m.png");
      background-position: 0 250px;
      background-repeat: no-repeat;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        background-image: url("../../images/about-background.png");
        background-position: -50px 100px;
        background-size: contain;
      }
    }
  
    &--services {
      margin: 0 auto;
      background-image: url("../../images/services-background.png");
      background-position: 150% 200px;
      background-repeat: no-repeat;
      background-size: contain;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        background-position: right 150px;
      }
    }
  }
  
  .webdoor__flame {
    position: absolute;
    top: 0;
    right: 0;
    width: 482px;
    height: 277px;
    transform: translateX(175px) translateY(-138px);
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      display: none;
    }
  }
  
  .webdoor__arcs {
    position: absolute;
    bottom: 0;
    right: 0;
    @include responsive-resize(width, $responsive-type-min-width, $responsive-type-max-width, 320px, 1920px);
    transform: translateY(4px);
  
    svg {
      width: 100%;
    }
  }
  
  .webdoor__image {
    user-select: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: px2rem(620px);
    background-image: url("../../images/virtugnl-webdoor-m.png");
    background-repeat: no-repeat;
    background-size: cover;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      left: 25%;
      background-image: none;
      transform: translateY(80px);
    }
  
    @media screen and (min-width: map-get($breakpoints, "lg")) {
      top: 0;
      bottom: initial;
      left: 25%;
      width: px2rem(1184px);
      height: px2rem(961px);
      transform: initial;
    }
  
    @media screen and (min-width: map-get($breakpoints, "xl")) {
      left: 25%;
    }
  
    @media screen and (min-width: map-get($breakpoints, "hg")) {
      left: initial;
      right: 0;
    }
  
    img {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
  
      @media screen and (min-width: map-get($breakpoints, "md")) {
        display: block;
      }
    }
  }
  
  .webdoor__content {
    position: relative;
  
    @media screen and (min-width: map-get($breakpoints, "md")) {
      @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 680px);
    }
  
    &--about {
      @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 900px);
  
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        margin: 0 auto;
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 800px);
      }
    }
  
    &--services {
      @media screen and (min-width: map-get($breakpoints, "md")) {
        max-width: 300px;
      }
  
      @media screen and (min-width: map-get($breakpoints, "lg")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 400px);
      }
  
      @media screen and (min-width: map-get($breakpoints, "xl")) {
        @include responsive-resize(max-width, $responsive-type-min-width, $responsive-type-max-width, 320px, 450px);
      }
    }
  }
  