.navigation {
  display: none;

  @media screen and (min-width: map-get($breakpoints, "lg")) {
    display: block;
  }

  ul {
    display: flex;
  }

  li {
    margin: 0 20px;

    &.current_page_item {
      a {
        color: var(--font-lightblue);
        font-weight: var(--bold);
        text-decoration: underline;
      }
    }
  }

  a {
    color: var(--font-dark);
    font-size: 18px;
    font-weight: var(--regular);

    @media screen and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
