*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-smooth: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--font-dark);
  font-family: var(--font);
  font-weight: var(--regular);
  font-style: normal;
}

button,
button:focus,
input,
input:focus,
select,
select:focus {
  outline: none;
  font-family: var(--font);
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}